.top-nav {
  position: fixed;
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-template-areas: "logo menunav lang";
  grid-area: nav;
  background-color: var(--mainColor);
  box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.2),
    0px 1px 3px 0 rgba(0, 0, 0, 0.15);
  align-items: center;
  padding: 5px 20px;
  z-index: 6;
  width: 100%;

  .burger-menu-icon {
    display: none;
  }
  .app-logo {
    height: 50px;
    width: 50px;
    cursor: pointer;
    grid-area: logo;
  }

  .nav-menu {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 7px;
    grid-area: menunav;
    .nav-item {
      cursor: pointer;
      font-size: 18px;

      &.dropdown {
        position: relative;
        display: inline-block;
        .dropdown-content {
          position: absolute;
          top: 40px;
          background-color: #f1f1f1;
          min-width: 160px;
          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
          z-index: 1;
          right: 0;

          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          padding: 1rem;

          [dir="rtl"] & {
            right: unset;
            left: 0;
          }
        }
      }

      &:last-child {
        border-left: 1px solid rgba(128, 128, 128, 0.5);
        [dir="rtl"] & {
          &:last-child {
            border-left: unset;
            border-right: 1px solid rgba(128, 128, 128, 0.5);
          }
        }
      }
      .nav-link {
        width: 100%;
        height: 100%;
        padding: 10px;
        text-decoration: none;
        white-space: nowrap;
        color: var(--secondaryColor);
        &:hover {
          background-color: var(--secondaryColor);
          color: var(--mainColor);
          border-radius: 5px;
        }
        &.active {
          background-color: var(--secondaryColor);
          color: var(--mainColor);
          border-radius: 5px;
        }
      }
    }
  }

  .notifictation-language {
    grid-area: lang;
    display: flex;
    align-items: center;
    gap: 10px;
    .lang-btn {
      background-color: transparent;
      border: none;
      padding: 10px;
      color: var(--secondaryColor);
      cursor: pointer;
      &:hover {
        background-color: var(--secondaryColor);
        color: var(--mainColor);
        border-radius: 5px;
      }
    }

    .notification-icon {
      cursor: pointer;
      width: 20px;
      height: 20px;
      fill: var(--secondaryColor);
    }
  }
  .small-notification-icon {
    display: none;
    cursor: pointer;
    width: 20px;
    height: 20px;
    fill: var(--secondaryColor);
  }
}
.base-nav-item {
  // grid-area: nav;
  // height: 60px;
}
@media screen and (max-width: 1070px) {
  .top-nav {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: "burger logo notifi";
    .burger-menu-icon {
      display: unset;
      width: 30px;
      height: 20px;
      fill: var(--secondaryColor);
      cursor: pointer;
      align-self: center;
      justify-self: start;
    }
    .app-logo {
      align-self: center;
      justify-self: center;
    }
    .small-notification-icon {
      display: unset;
      align-self: center;
      justify-self: end;
    }
    .nav-menu {
      display: none;
    }
    .account-btn-container {
      display: none;
    }
    .notifictation-language {
      display: none;
    }
  }
}
