.chat-page-container {
  @apply flex flex-row h-full overflow-hidden;

  .chat-sidebar {
    @apply bg-primary w-80 overflow-hidden py-5 pl-3 flex flex-col h-full gap-4;

    .active-room-tab {
      @apply bg-white relative;
      &::before {
        content: "";
        position: absolute;
        top: -40px;
        right: 0;
        height: 40px;
        width: 40px;
        border-bottom-right-radius: 50%;

        box-shadow: 0px 20px 0 0 white;
      }
    }
  }

  @media screen and (max-width: 640px) {
    & {
      margin: 0 !important;
      padding: 0 !important;
      border-radius: 0 !important;
    }
  }
}
@media screen and (max-width: 568px) {
  .page-content {
    margin: 0 !important;
    padding: 0 !important;
  }
}
.chat-modal {
  @apply flex flex-col gap-3;
}
