$media-md: 764px;
.main-image-label {
  border: 1px solid #707070;
  border-radius: 7px;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-inline: auto;
  aspect-ratio: 1;
  max-width: 300px;
  background: rgba(128, 128, 128, 0.4);
  @media screen and (max-width: $media-md) {
    // width: 100%;
    max-width: 100%;
  }
  .uploaded-img {
    width: clamp(200px, 100%, 300px);
    height: auto;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 7px;

    max-width: 100%;
  }
  .main-input-image {
    display: none;
  }
  .main-label-image {
    z-index: 3;
    position: absolute;
    height: 30px;
    width: 30px;
    right: 1rem;
    bottom: 1rem;
    display: flex;
    font-size: 20px;
    color: var(--secondaryColor);
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #00000040;
    border-radius: 70px;
    padding: 20px;
    transition: color ease-in-out 150ms;
    box-shadow: 3px 2px 10px 1px #fff9;
    outline: 1px solid #0000006a;
    &:hover {
      background-color: var(--mainColor);
      color: #00000080;
      outline: 1px solid var(--mainColor);
    }
    &:active {
      transform: scale(0.9);
    }
  }
}
