@import "src/styles/colors";

.tabs-container {
  width: 100%;
  max-width: 80%;
  margin-inline: auto;

  .tab-btns {
    margin-block: 1rem;
    max-width: 80%;
    margin-inline: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    .btn {
      font-size: 0.8rem;
      font-weight: bolder;
      padding: 0.5rem 1rem;
      border-radius: 0.3rem;
      background-color: $light;
      cursor: pointer;
      transition: background-color 0.3s ease;
      border: none;
      outline: 1px solid rgba(0, 0, 0, 0.5);
      box-shadow: 1px 2px 5px 2px rgba(0, 0, 0, 0.25);
      white-space: nowrap;

      &:hover {
        box-shadow: 1px 2px 4px 1px rgba(0, 0, 0, 0.25);
      }

      &.active {
        color: $secondaryColor;
        background-color: $primary;
        box-shadow: 1px 2px 1px 1px rgba(0, 0, 0, 0.25);
      }
    }
  }
}
