@import "src/styles/colors";

.btn {
  cursor: pointer;
  transition: color 150ms ease-in-out;
  transition: transform 50ms cubic-bezier(0.165, 0.84, 0.44, 1);

  &.primary {
    color: $mainColor;
  }

  &:hover {
    color: darken($color: $mainColor, $amount: 5);
  }
  &:active {
    color: darken($color: $mainColor, $amount: 10);
    transform: scale(0.95);
  }
}
