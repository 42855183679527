.backdrop {
  position: fixed;
  inset: 0;
  z-index: 999;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.2);
  user-select: none;
  .modal-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: center center;

    .modal {
      max-width: 80vw;
      width: fit-content;

      min-width: 250px;
      padding: 1rem;
      max-height: 85vh;

      background: white;
      color: black;
      z-index: 10;
      border-radius: 16px;
      box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);

      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 1rem;

      .header {
        display: inline-flex;
        flex-direction: row;
        justify-content: center;
        align-items: baseline;
        width: 100%;

        .title {
          color: black;
          font-size: 1.2rem;
        }
        .close {
          margin-left: auto;
        }
      }

      .footer {
        margin-top: auto;
        font-size: 0.9rem;
      }

      @media screen and (max-width: 640px) {
        max-width: 90vw;
        width: 90vw;
      }
      @media screen and (max-width: 440px) {
        max-width: 100vw;
        width: 100vw;
        border-radius: 0;
      }
    }
  }
}

:global(.modal-open) {
  position: fixed;
  inset: 0;
}
