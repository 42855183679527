@import "src/styles/colors";

.sponsor-page {
  .page-title {
    color: var(--mainColor);
    text-align: center;
    padding-block: 2rem;
  }
  .page-container {
    width: clamp(250px, 40%, 700px);
    margin: 0 auto;
    padding: 3rem 0;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: 2rem;

    button {
      width: 100%;
      height: 5rem;
      font-size: 1.5rem;
      background-color: white;
      color: $primary;
      border-color: $primary;
      border-style: solid;
      border-radius: 0.5rem;
      cursor: pointer;
      &:hover {
        background-color: lighten($color: $primary, $amount: 35);
      }
    }
  }
}
