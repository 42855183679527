.no-data {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 30px;
  flex-direction: column;
  .no-data-svg {
    [fill] {
      fill: var(--mainColor);
    }
  }
  .no-data-text {
    color: var(--mainColor);
  }
}
