@import "src/styles/index";
@import "src/styles/_colors";

.sub-agents-page {
  .add-agent-btn {
    @apply ml-auto;
    color: $mainColor;
  }
  .agent-card {
    // display: grid;
    // grid-template-areas:
    //   "name-icon name chat-icon"
    //   "details-icon details ."
    //   "credits-icon credits .";
    // grid-template-columns: min-content 1fr min-content;
    row-gap: 0.2rem;
    column-gap: 0.6rem;

    padding: 0.7rem;
    border: 1px solid $light;
    border-radius: 0.7rem;
    box-shadow: 1px 3px 5px rgba(128, 128, 128, 0.1);

    width: 20rem;
    min-width: 15rem;

    .icon {
    }

    .name-icon {
      @apply flex-center p-3;
      grid-area: name-icon;
      color: $mainColor;
    }
    .details-icon {
      grid-area: details-icon;
      @apply flex-center p-3;
    }
    .credits-icon {
      grid-area: credits-icon;
      @apply flex-center p-3;
    }
    .name-text {
      @apply flex items-center;
      grid-area: name;
      color: $mainColor;
    }
    .details-text {
      grid-area: details;
      max-width: 100%;
      text-overflow: ellipsis;
      @apply flex items-center;
    }
    .credits-text {
      grid-area: credits;
      @apply flex items-center;
    }
    .chat-icon {
      grid-area: chat-icon;
      color: $mainColor;
      @apply flex-center p-3;

      .icon {
        transition: transform 100ms ease-in-out;

        &.disabled {
          color: gray;
        }
        &:hover {
          cursor: pointer;
        }
        &:active {
          transform: scale(0.9);
        }
      }
    }
  }
}
