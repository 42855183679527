@import "src/styles/colors";
.add-button-container {
  width: 100%;
  margin: 10px 0;
  padding: 0 1rem;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  .add-button {
    height: 40px;
    padding: 10px;
    border: none;
    border-radius: 7px;

    color: white;
    background-color: $mainColor;
    font-size: 18px;
    cursor: pointer;

    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 0.3rem;

    box-shadow: 0px 5px 5px -4px rgba(0, 0, 0, 0.25);
    transition: all cubic-bezier(0.23, 1, 0.32, 1) 200ms;
    span {
      display: flex;
    }
    &:hover {
      box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.25);
    }
    &:active {
      box-shadow: inset 0px 5px 5px -4px rgba(0, 0, 0, 0.25);
      transform: scale(0.98);
    }
  }
}
