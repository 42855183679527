.routing-tabs-container {
  height: 50px;
  width: 100%;
  border: 1px solid var(--mainColor);
  border-radius: 5px;
  margin: 10px 0;
  padding: 5px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  .tab-route {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .tab-link {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      color: var(--mainColor);
      border-radius: 5px;
      &.active {
        color: var(--secondaryColor);
        background-color: var(--mainColor);
      }
    }
  }
}
