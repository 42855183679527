$mainColor: #fc7300;
$mainColorLight: #f89a3663;
$secondaryColor: #ffffff;

$primary: #fc7300;
$primary-light: #ffeace;

$secondary: #005247;
$secondary-light: #c3fcf1;

$warning: #ff8886;
$warning-light: #faebe6;

$light: #dfe0df;
$dark: #402e32;
